const parseJSONSafely = str => {
    try {
       return JSON.parse(str);
    }
    catch (e) {
       console.log(e);
       return []
    }
 }

export default parseJSONSafely;
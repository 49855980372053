<template>
    <v-text-field
        :value="convertedInput"
        :rules="computedRules"
        :label="label"
        @input="handleInput" />
</template>

<script>
    import { currencyRules, requiredRules } from '@/helpers/validationRules';

    export default {
        props: ['value', 'label'],
        computed: {
            computedRules() {
                return [...requiredRules, ...currencyRules('US')];
            },
            convertedInput() {
                if (isNaN(this.value)) {
                    return 0;
                }
                return (Math.abs(Number(this.value)) / 1.0e6);
            }
        },
        methods: {
            handleInput(value) {
                if (isNaN(value)) {
                    return;
                }
                const convertedValue = Number(value) * 1.0e6;
                this.$emit('input', convertedValue);
            }
        }
    };
</script>
<template>
    <v-layout>
        <v-flex
            xs12
            md8
            offset-md2
            mt-5>
            <v-card
                light
                class="wrap-content gray-icons">
                <v-toolbar
                    dark
                    color="white"
                    class="first-line-toolbar">
                    <h2 class="primary--text font-weight-regular mb-3 pl-2 pt-4">
                        Build Snapchat Plays
                    </h2>
                    <v-spacer />
                </v-toolbar>
                <v-card-text>
                    <v-container
                        fluid
                        grid-list-md>
                        <v-stepper
                            v-model="e1"
                            light>
                            <v-stepper-header>
                                <v-stepper-step
                                    step="1"
                                    :complete="e1 > 1">
                                    Select dealer
                                </v-stepper-step>
                                <v-divider />
                                <v-stepper-step
                                    step="2"
                                    :complete="e1 > 2">
                                    Select play
                                </v-stepper-step>
                                <v-divider />
                                <v-stepper-step
                                    step="3"
                                    :complete="e1 > 3">
                                    Configure play
                                </v-stepper-step>
                            </v-stepper-header>
                            <v-stepper-items>
                                <v-stepper-content step="1">
                                    <v-form class="px-2">
                                        <v-layout
                                            row
                                            class="px-5 py-1">
                                            <v-flex xs12>
                                                <dealer-picker-global />
                                            </v-flex>
                                        </v-layout>
                                        <v-layout
                                            class="py-5 ma-0 form-button"
                                            row
                                            justify-center
                                            align-center>
                                            <v-flex xs12>
                                                <div class="text-xs-center">
                                                    <v-btn
                                                        flat
                                                        color="white"
                                                        large
                                                        @click.native="e1++">
                                                        Continue
                                                    </v-btn>
                                                </div>
                                            </v-flex>
                                        </v-layout>
                                    </v-form>
                                </v-stepper-content>
                                <v-stepper-content step="2">
                                    <v-form class="px-2">
                                        <v-layout
                                            row
                                            class="px-5 py-1">
                                            <v-flex xs12>
                                                <v-autocomplete
                                                    v-model="selectedPlay"
                                                    :items="plays"
                                                    item-text="display_name"
                                                    :loading="loadingPlays"
                                                    label="Play"
                                                    return-object
                                                    light />
                                            </v-flex>
                                        </v-layout>
                                        <v-layout
                                            row
                                            class="px-5 py-1">
                                            <v-flex xs12>
                                                <styled-alert
                                                    v-if="errorMessage"
                                                    type="error">
                                                    {{ currentDealer.name }} is unable to deploy Snapchat Plays because of following errors:
                                                    <ul
                                                        v-for="(error, index) in errorMessage"
                                                        :key="index">
                                                        <li>{{ error }}</li>
                                                    </ul>
                                                    Please contact Support for assistance.
                                                </styled-alert>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout
                                            class="py-5 ma-0 form-button"
                                            row
                                            justify-center
                                            align-center>
                                            <v-flex xs12>
                                                <div class="text-xs-center">
                                                    <v-btn
                                                        flat
                                                        large
                                                        color="white"
                                                        @click.native="e1--">
                                                        Back
                                                    </v-btn>
                                                    <v-btn
                                                        flat
                                                        large
                                                        color="white"
                                                        :disabled="!playValidated"
                                                        @click.native="e1++">
                                                        Continue
                                                    </v-btn>
                                                </div>
                                                <div
                                                    v-if="validatingPlay"
                                                    class="text-xs-center">
                                                    <loader
                                                        label="Validating Play..." />
                                                </div>
                                            </v-flex>
                                        </v-layout>
                                    </v-form>
                                </v-stepper-content>
                                <v-stepper-content step="3">
                                    <v-form
                                        v-if="selectedPlay !== null"
                                        ref="form"
                                        v-model="valid"
                                        class="px-2"
                                        @submit.prevent="() => null">
                                        <h2 class="pt-4 pb-2 headline">
                                            Campaign Options
                                        </h2>
                                        <v-layout
                                            v-for="(campaign, campaignKey) in selectedPlay.configuration.campaigns"
                                            :key="campaignKey"
                                            wrap
                                            row
                                            class="px-5 py-3">
                                            <h3 class="pt-4 pb-2 headline">
                                                {{ selectedPlay.configuration.campaigns[campaignKey].template.name }}
                                            </h3>
                                            <v-flex xs12>
                                                <v-text-field
                                                    v-model="selectedPlay.configuration.campaigns[campaignKey].template.name"
                                                    label="Campaign Name"
                                                    light />
                                            </v-flex>
                                            <v-flex xs12>
                                                <currency-translator
                                                    v-model="selectedPlay.configuration.campaigns[campaignKey].template.daily_budget_micro"
                                                    label="Daily Budget"
                                                    light />
                                            </v-flex>
                                            <v-flex xs12>
                                                <template>
                                                    <h2 class="pt-4 pb-2 headline">
                                                        Ad Squad Options
                                                    </h2>
                                                    <v-layout
                                                        v-for="(adSquad, adSquadKey) in selectedPlay.configuration.campaigns[campaignKey].ad_squads"
                                                        :key="adSquadKey"
                                                        row
                                                        wrap
                                                        class="px-5 py-3">
                                                        <h3 class="pt-4 pb-2 headline">
                                                            {{ selectedPlay.configuration.campaigns[campaignKey].ad_squads[adSquadKey].template.name }}
                                                        </h3>
                                                        <v-flex xs12>
                                                            <currency-translator
                                                                v-model="selectedPlay.configuration.campaigns[campaignKey].ad_squads[adSquadKey].template.daily_budget_micro"
                                                                label="Daily budget"
                                                                light />
                                                        </v-flex>
                                                        <template v-if="selectedPlay.configuration.campaigns[campaignKey].ad_squads[adSquadKey].template.bid_strategy == 'TARGET_COST'">
                                                            <v-flex xs12>
                                                                <currency-translator
                                                                    v-model="selectedPlay.configuration.campaigns[campaignKey].ad_squads[adSquadKey].template.bid_micro"
                                                                    label="Bid"
                                                                    light />
                                                            </v-flex>
                                                        </template>
                                                        <template v-if="selectedPlay.configuration.campaigns[campaignKey].ad_squads[adSquadKey].template.targeting.hasOwnProperty('locations')">
                                                            <v-flex xs12>
                                                                <v-text-field
                                                                    v-model="selectedPlay.configuration.campaigns[campaignKey].ad_squads[adSquadKey].template.targeting.locations[0].circles[0].radius"
                                                                    label="Radius"
                                                                    light />
                                                            </v-flex>
                                                        </template>
                                                        <template v-if="updated">
                                                            <v-flex
                                                                v-for="(segment, segmentKey) in selectedPlay.configuration.campaigns[campaignKey].ad_squads[adSquadKey].template.targeting.hasOwnProperty('segments') ? selectedPlay.configuration.campaigns[campaignKey].ad_squads[adSquadKey].template.targeting.segments : []"
                                                                :key="segmentKey"
                                                                xs12>
                                                                <v-autocomplete
                                                                    v-model="selectedPlay.configuration.campaigns[campaignKey].ad_squads[adSquadKey].template.targeting.segments[segmentKey].segment_id[0]"
                                                                    :items="segments"
                                                                    item-text="name"
                                                                    item-value="id"
                                                                    :loading="loadingSegments"
                                                                    label="Segment"
                                                                    light />
                                                            </v-flex>
                                                        </template>
                                                        <v-flex
                                                            xs12
                                                            class="form-button">
                                                            <v-btn
                                                                flat
                                                                color="white"
                                                                large
                                                                @click.native="addSegment(campaignKey, adSquadKey)">
                                                                Add Segment
                                                            </v-btn>
                                                        </v-flex>
                                                        <v-flex xs12>
                                                            <template>
                                                                <h2 class="pt-4 pb-2 headline">
                                                                    Ad Creative Options
                                                                </h2>
                                                                <v-layout
                                                                    v-for="(ad, adKey) in selectedPlay.configuration.campaigns[campaignKey].ad_squads[adSquadKey].ads"
                                                                    :key="adKey"
                                                                    row
                                                                    wrap
                                                                    class="px-5 py-3">
                                                                    <h3 class="pt-4 pb-2 headline">
                                                                        {{ selectedPlay.configuration.campaigns[campaignKey].ad_squads[adSquadKey].ads[adKey].template.name }}
                                                                    </h3>
                                                                    <v-flex
                                                                        v-for="(adCreative, adCreativeKey) in selectedPlay.configuration.campaigns[campaignKey].ad_squads[adSquadKey].ads[adKey].ad_creatives"
                                                                        :key="adCreativeKey"
                                                                        xs12>
                                                                        <v-text-field
                                                                            v-model="selectedPlay.configuration.campaigns[campaignKey].ad_squads[adSquadKey].ads[adKey].ad_creatives[adCreativeKey].template.headline"
                                                                            label="Headline"
                                                                            light />
                                                                    </v-flex>
                                                                </v-layout>
                                                            </template>
                                                        </v-flex>
                                                    </v-layout>
                                                </template>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout
                                            row
                                            class="px-5 py-1">
                                            <v-flex xs12>
                                                <v-list
                                                    v-for="update in updates.slice().reverse()"
                                                    :key="update.id"
                                                    light>
                                                    <v-list-tile
                                                        v-clipboard:copy="update.value"
                                                        v-clipboard:success="onCopy"
                                                        v-clipboard:error="onError"
                                                        avatar
                                                        @click.prevent>
                                                        <v-list-tile-content>
                                                            <v-list-tile-action-text>
                                                                {{ update.value }}...
                                                            </v-list-tile-action-text>
                                                        </v-list-tile-content>
                                                    </v-list-tile>
                                                </v-list>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout
                                            class="py-5 ma-0 form-button"
                                            row
                                            justify-center
                                            align-center>
                                            <v-flex xs12>
                                                <div class="text-xs-center">
                                                    <v-btn
                                                        flat
                                                        large
                                                        color="white"
                                                        @click.native="e1--">
                                                        Back
                                                    </v-btn>
                                                    <v-btn
                                                        flat
                                                        large
                                                        color="white"
                                                        @click.native="buildAds()">
                                                        Build
                                                        Ads
                                                    </v-btn>
                                                </div>
                                            </v-flex>
                                        </v-layout>
                                    </v-form>
                                </v-stepper-content>
                            </v-stepper-items>
                        </v-stepper>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-flex>
    </v-layout>
</template>

<script>
    import HTTP from '@/http';
    import DealerPickerGlobal from '@/components/globals/DealerPickerGlobal';
    import { mapState } from 'vuex';
    import ApiQuery from '@/api-query';
    import CurrencyTranslator from '@/components/dealers/BuildSnapInventory/CurrencyTranslator';
    import Loader from '@/components/globals/Loader';
    import StyledAlert from '@/components/globals/StyledAlert';
    import parseJSONSafely from '@/helpers/parseJSONSafely';

    export default {
        components: {
            Loader,
            DealerPickerGlobal,
            CurrencyTranslator,
            StyledAlert
        },
        data() {
            return {
                e1: null,
                valid: false,
                loadingPlays: true,
                loadingSegments: true,
                segmentAdded: false,
                validatingPlay: false,
                selectedPlay: null,
                playValidated: false,
                updated: true,
                errorMessage: null,
                imagePath: [],
                plays: [],
                segments: [],
                updates: [],
                count: 0
            };
        },
        computed: {
            ...mapState({
                currentDealer: (state) => state.dealer.currentDealer,
            }),
        },
        watch: {
            async selectedPlay() {
                try {
                    this.errorMessage = null;

                    if (this.selectedPlay != null) {
                        this.playValidated = false;
                        this.validatingPlay = true;

                        await (new HTTP)
                            .post(
                                '/dealers/' + this.currentDealer.id + '/validate-snapchat-play',
                                {
                                    dependencies: this.selectedPlay.dependencies
                                }
                            );

                        this.playValidated = true;
                        this.validatingPlay = false;

                        const response = await (new ApiQuery('/dealers/' + this.currentDealer.id + '/snapchat-audience-segments'))
                            .setPageSize(1000)
                            .get();

                        console.log(response);
                        this.segments = response.data;
                        this.loadingSegments = false;
                    }
                } catch (error) {
                    console.log(error);
                    this.$flash(error.response.data.error.messages[0], 'red');
                    this.validatingPlay = false;
                    this.errorMessage = error.response.data.error.messages[0];
                }
            },
            currentDealer() {
                this.selectedPlay = null;
                this.playValidated = false;
            },
            errorMessage() {
                if (typeof this.errorMessage === 'string') {
                    const parsedErrors = parseJSONSafely(this.errorMessage);
                    let errorsList = [];
                    parsedErrors.forEach(errorMessage => {
                        errorsList.push(errorMessage?.[0].errors?.[0] || 'Unknown error');
                    });
                    this.errorMessage = errorsList;
                }
            }
        },
        mounted() {
            (new ApiQuery('plays'))
                .withScope('plays.is_snapchat')
                .setPageSize(1000)
                .get()
                .then(response => {
                    this.plays = response.data.data;
                    this.loadingPlays = false;
                })
                .catch(e => {
                    console.log(e);
                });
        },
        methods: {
            async buildAds() {
                try {
                    this.$refs.form.validate();

                    await this.$nextTick();

                    if (!this.valid) {
                        return;
                    }

                    const response = await (new HTTP)
                        .post(
                            '/dealers/' + this.currentDealer.id + '/build-snapchat-play',
                            {
                                configuration: this.selectedPlay.configuration,
                                dependencies: this.selectedPlay.dependencies,
                            }
                        );

                    this.$flash('Play build started', 'green');
                    this.updates.push({ id: this.count, value: 'Play build started' });
                    this.count++;
                    this.$echo.channel('play-build-channel-' + response.data.uuid)
                        .listen('PlayBuilderEvent', (e) => {
                            this.updates.push({ id: this.count, value: e.message });
                            this.count++;
                            console.log(e);
                        });
                } catch (error) {
                    console.log(error.response);
                    this.$flash(error.response.data.error.messages[0], 'red');
                    this.updates.push(error.response.data.error.messages[0]);
                }
            },
            addSegment(campaignKey, adSquadKey) {
                this.updated = false;
                if (this.selectedPlay.configuration.campaigns[campaignKey].ad_squads[adSquadKey].template.targeting.hasOwnProperty('segments')) {
                    this.selectedPlay.configuration.campaigns[campaignKey].ad_squads[adSquadKey].template.targeting.segments.push({
                        segment_id: [null]
                    });
                } else {
                    this.selectedPlay.configuration.campaigns[campaignKey].ad_squads[adSquadKey].template.targeting.segments = [
                        {
                            segment_id: [null]
                        }
                    ];
                }
                console.log(this.selectedPlay.configuration);
                this.updated = true;
            },
            removeSegment(campaignKey, adSquadKey, segmentKey) {
                this.updated = false;
                delete this.selectedPlay.configuration.campaigns[campaignKey].ad_squads[adSquadKey].template.targeting.segments[segmentKey];
                console.log(this.selectedPlay.configuration);
                this.updated = true;
            },
            getSegments(campaignKey, adSquadKey) {
                if (this.selectedPlay.configuration.campaigns[campaignKey].ad_squads[adSquadKey].template.targeting.hasOwnProperty('segments')) {
                    return this.selectedPlay.configuration.campaigns[campaignKey].ad_squads[adSquadKey].template.targeting.segments;
                } else {
                    return [];
                }
            },
            onCopy(e) {
                this.$flash('\'' + e.text + '\' copied to clipboard!', 'green');
            },
            onError() {
                this.$flash('Failed to copy text.', 'red');
            }
        }
    };
</script>


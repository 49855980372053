<template>
    <div
        class="alert-wrapper"
        :class="type">
        <slot />
    </div>
</template>

<script>
export default {
  props: {
    type: {
      type: [String],
      required: false,
      default: 'info'
    }
  },
  data() {
    return {
      showAsList: false
    };
  }
};
</script>

<style lang="scss" scoped>
  .alert-wrapper{
    padding: 15px;
    border-radius: 5px;
    color:$white;
  }
  .info {
    background: $blue-bg !important;
  }
  .error {
    background: $error-500;
  }
  .warning{
    background: $yellow-dark !important;
  }
  .success{
    background: $success-500;
  }
</style>